import { Injectable } from '@angular/core';
import { BACKEND_API } from '../constants/api.constants';
import { Observable } from 'rxjs';
import { ApiService } from './api.service';
import {
  JwtToken,
  LoginFormData,
  RequestOtpDto,
  RequestOtpResponse,
  ResendOtpDto,
  ResetPasswordDto,
} from '../models/auth.models';
import { Router } from '@angular/router';
import { FileService } from './file.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(
    private apiService: ApiService,
    private router: Router,
    private fileService: FileService
  ) {}

  login(data: LoginFormData): Observable<JwtToken> {
    return this.apiService.post(BACKEND_API.auth, data);
  }

  requestForgotPasswordOtp(
    data: RequestOtpDto
  ): Observable<RequestOtpResponse> {
    return this.apiService.post(BACKEND_API.forgetPassword, data);
  }

  resendForgotPasswordOtp(data: ResendOtpDto): Observable<void> {
    return this.apiService.post(BACKEND_API.resendForgetPasswordOtp, data);
  }

  resetPassword(data: ResetPasswordDto): Observable<void> {
    return this.apiService.post(BACKEND_API.resetPassword, data);
  }

  clearLocalStorage(): void {
    localStorage.removeItem('accessToken');
  }

  setLocalStorage(token: JwtToken): void {
    localStorage.setItem('accessToken', token.accessToken);
    localStorage.setItem('profilePicture', token.profilePicture || '');
  }

  getProfilePicture(): string {
    const profilePicture = localStorage.getItem('profilePicture');
    return profilePicture ? this.fileService.getUrlPath(profilePicture) : '';
  }

  isLoggedIn(): boolean {
    return Boolean(localStorage.getItem('accessToken'));
  }

  logout(): void {
    this.clearLocalStorage();
    this.router.navigate(['/login']);
  }
}
